<template>
  <div class="auth pt-0 pb-20 lg:py-20">
    <MobileHeader class="block lg:hidden pt-4 pl-4" />

    <main class="">
      <section class="hidden lg:flex flex-col justify-center items-center">
        <img
          alt="ansaa logo"
          src="../assets/images/logo.svg"
          class="w-56 h-42"
        />
        <img alt="ansaa logo text" src="../assets/images/logo_text-white.svg" />
      </section>

      <section class="form">
        <div>
          <h5 class="text-ansGreen text-2xl font-bold text-center">
            Create an Account
          </h5>
        </div>

        <Lines class="pt-8" />

        <!-- FORM AREA -->
        <div class="pt-8">
          <label for="name">Full name<span class="err">*</span></label>
          <input
            type="text"
            class="input"
            id="name"
            placeholder="Enter your full name"
            v-model="form.name"
          />
        </div>

        <div class="pt-8">
          <label for="email">Email<span class="err">*</span></label>
          <input
            type="email"
            class="input"
            id="email"
            placeholder="Enter your email"
            v-model="form.email"
          />
        </div>

        <div class="pt-8">
          <label for="phone">Phone Number<span class="err">*</span></label>
          <input
            type="text"
            class="input"
            id="phone"
            placeholder="Enter your phone number"
            v-model="form.phone"
          />
        </div>

        <div class="pt-6">
          <label for="password">Password<span class="err">*</span></label>
          <PasswordInput id="password" v-model="form.password" />
          <small v-if="passwordNotReady" class="err">
            Password must be up to 7 characters
          </small>
        </div>

        <div class="pt-6">
          <label for="confirmPassword"
            >Confirm Password<span class="err">*</span></label
          >
          <PasswordInput
            id="confirmPassword"
            v-model="form.confirmPassword"
            placeholder="Confirm your password"
          />
          <small v-if="passwordNotMatch" class="err">
            Password must match Confirm password
          </small>
        </div>

        <div class="mt-6">
          <button
            :disabled="!signupReady || signupProcessing"
            type="submit"
            @click="signup"
            class="flex justify-center bg-ansLemon text-white py-3 w-full rounded-xl"
          >
            <span class="mr-1">Sign up</span>
            <spinner v-if="signupProcessing" />
          </button>
        </div>

        <div class="flex justify-center mt-6 text-xs">
          <span class="text-oldSilver"> Already have an account? </span>

          <router-link
            :to="{
              name: 'login',
            }"
            class="text-spanGreen ml-1 font-medium"
          >
            Login here
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Lines from "@/components/Lines.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import MobileHeader from "@/components/auth/MobileHeader.vue";

export default {
  name: "SignUp",
  components: {
    PasswordInput,
    Lines,
    MobileHeader,
  },
  data() {
    return {
      signupProcessing: false,
      form: {
        name: null,
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    passwordNotReady() {
      return this.form.password && this.form.password.length < 7;
    },
    passwordNotMatch() {
      return (
        this.form.confirmPassword &&
        this.form.confirmPassword != this.form.password
      );
    },
    signupReady() {
      return (
        this.form.name &&
        this.form.email &&
        this.form.password &&
        !this.passwordNotReady &&
        !this.passwordNotMatch
      );
    },
  },
  methods: {
    async signup() {
      try {
        this.signupProcessing = true;

        const res = await this.$http.post("/signup", this.form, {
          headers: { noauth: true },
        });
        this.signupProcessing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$store.dispatch("saveUserInfo", this.form);

        this.$router.push({ name: "verify-account" });
      } catch (err) {
        console.log(err);
        this.signupProcessing = false;
      }
    },
  },
};
</script>

<style scoped>
main {
  @apply flex justify-evenly items-center mt-20 lg:mt-0;
  /* height: 100vh; */
  width: 100%;
}
.form {
  @apply py-8 px-4 lg:py-24 lg:px-10 w-11/12;
  background: #ffffff;
  border-radius: 2rem;
  max-width: 31.25rem;
}
.err {
  @apply text-red-400 text-xs font-bold;
}
</style>
