<template>
  <div class="auth">
    <MobileHeader class="block lg:hidden pt-4 pl-4" />

    <main class="">
      <section class="hidden lg:flex flex-col justify-center items-center">
        <img
          alt="ansaa logo"
          src="../assets/images/logo.svg"
          class="w-56 h-42"
        />
        <img alt="ansaa logo text" src="../assets/images/logo_text-white.svg" />
      </section>

      <section class="form">
        <div>
          <h5 class="text-ansGreen text-2xl font-bold text-center">
            Verify your Account
          </h5>
        </div>

        <Lines class="pt-8" />

        <!-- FORM AREA -->
        <div class="pt-8">
          <label for="type">Choose account detail to verify</label>
          <v-select
            label="label"
            :reduce="(b) => b.value"
            :options="options"
            placeholder="Choose one account detail"
            v-model="form.type"
          ></v-select>
        </div>

        <div class="pt-8">
          <label for="otp">Enter OTP</label>
          <input
            type="text"
            class="input"
            id="otp"
            pattern="/^-?\d+\.?\d*$/"
            onKeyPress="if(this.value.length==4) return false;"
            placeholder="Enter OTP"
            v-model="form.otp"
          />
        </div>

        <div class="mt-7">
          <button
            :disabled="!verifyReady || verifyProcessing"
            type="submit"
            @click="verify"
            class="flex justify-center bg-ansLemon text-white py-3 w-full rounded-xl"
          >
            <span class="mr-1">Verify</span>
            <spinner v-if="verifyProcessing" />
          </button>
        </div>

        <div class="flex justify-center mt-6 text-xs">
          <span class="text-oldSilver"> Don't have an account? </span>

          <router-link
            :to="{ name: 'signup' }"
            class="text-spanGreen ml-1 font-medium"
          >
            Sign up here
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Lines from "@/components/Lines.vue";
import MobileHeader from "@/components/auth/MobileHeader.vue";

export default {
  name: "VerifyAccount",
  components: {
    Lines,
    MobileHeader,
  },
  data() {
    return {
      verifyProcessing: false,
      form: {
        type: 1,
        otp: null,
      },
      options: [
        { label: "Phone", value: 1 },
        {
          label: "Email",
          value: 2,
        },
      ],
    };
  },
  computed: {
    verifyReady() {
      return this.form.type && this.form.otp && this.form.otp.length == 4;
    },
  },
  methods: {
    async verify() {
      try {
        this.verifyProcessing = true;

        const res = await this.$http.post(
          "/verify-otp",
          {
            ...this.form,
            username:
              this.form.type == 1
                ? this.$store.getters.userPhone
                : this.$store.getters.userEmail,
          },
          {
            headers: { noauth: true },
          }
        );
        this.verifyProcessing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push({ name: "login" });
      } catch (err) {
        console.log(err);
        this.verifyProcessing = false;
      }
    },
  },
};
</script>

<style scoped>
main {
  @apply flex justify-evenly items-center;
  height: 90vh;
  width: 100%;
}
.form {
  @apply py-8 px-4 lg:py-24 lg:px-10 w-11/12;
  background: #ffffff;
  border-radius: 2rem;
  max-width: 31.25rem;
}
</style>
