<template>
  <div class="password mt-1">
    <div class="w-11/12">
      <input
        v-if="isOpen"
        type="text"
        v-model="inputVal"
        :placeholder="placeholder"
      />
      <input
        v-else
        type="password"
        v-model="inputVal"
        :placeholder="placeholder"
      />
    </div>
    <div class="w-1/12 flex flex-col justify-center">
      <button @click="isOpen = !isOpen" class="w-full h-full">
        <img
          :src="require(`@/assets/images/icons/${iconName}.svg`)"
          alt="icons"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Enter your password",
    },
  },
  computed: {
    iconName() {
      return this.isOpen ? "eye-off" : "eye";
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.password {
  @apply flex w-full h-14 justify-between items-center px-4;
  border-radius: 0.75rem;
  background: #f9f9f9;
}
.password:focus-within {
  border: 1px solid #9cd1b8;
}
button:focus {
  outline: none;
}
button img {
  width: 1.406rem;
  height: 1.125rem;
}
input {
  background: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
</style>
