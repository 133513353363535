<template>
  <div class="auth">
    <MobileHeader class="block lg:hidden pt-4 pl-4" />

    <main class="">
      <section class="hidden lg:flex flex-col justify-center items-center">
        <img
          alt="ansaa logo"
          src="../assets/images/logo.svg"
          class="w-56 h-42"
        />
        <img alt="ansaa logo text" src="../assets/images/logo_text-white.svg" />
      </section>

      <section class="form">
        <div>
          <h5 class="text-ansGreen text-2xl font-bold text-center">
            Forgot Password
          </h5>
        </div>

        <Lines class="pt-8" />

        <!-- FORM AREA -->
        <div class="pt-8">
          <label for="email">Enter phone number to reset password</label>
          <input
            type="email"
            class="input"
            id="email"
            placeholder="Enter your phone number"
            v-model="form.username"
          />
        </div>

        <div class="mt-6">
          <button
            :disabled="!verifyReady || verifyProcessing"
            type="submit"
            @click="verify"
            class="flex justify-center bg-ansLemon text-white py-3 w-full rounded-xl"
          >
            <span class="mr-1">Verify</span>
            <spinner v-if="verifyProcessing" />
          </button>
        </div>

        <div class="flex justify-center mt-6 text-xs">
          <span class="text-oldSilver"> Remembered your password? </span>

          <router-link
            :to="{
              name: 'login',
            }"
            class="text-spanGreen ml-1 font-medium"
          >
            Login here
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Lines from "@/components/Lines.vue";
import MobileHeader from "@/components/auth/MobileHeader.vue";

export default {
  name: "ForgotPassword",
  components: {
    Lines,
    MobileHeader,
  },
  data() {
    return {
      verifyProcessing: false,
      form: {
        username: null,
        type: 3,
      },
    };
  },
  computed: {
    verifyReady() {
      return !!this.form.username;
    },
  },
  methods: {
    async verify() {
      try {
        this.verifyProcessing = true;

        const res = await this.$http.post("/get-otp", this.form, {
          headers: { noauth: true },
        });

        this.verifyProcessing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$store.dispatch("saveUserInfo", { phone: this.form.username });

        this.$router.push({ name: "update-password" });
      } catch (err) {
        console.log(err);
        this.verifyProcessing = false;
      }
    },
  },
};
</script>

<style scoped>
main {
  @apply flex justify-evenly items-center;
  height: 90vh;
  width: 100%;
}
.form {
  @apply py-8 px-4 lg:py-24 lg:px-10 w-11/12;
  background: #ffffff;
  border-radius: 2rem;
  max-width: 31.25rem;
}
</style>
