<template>
  <header>
    <router-link to="/" class="flex items-center">
      <img
        alt="ansaa logo"
        src="../../assets/images/logo.svg"
        class="h-8 w-11"
      />
      <img
        alt="ansaa logo text"
        src="../../assets/images/logo_text-white.svg"
        class="h-4 w-18"
      />
    </router-link>
  </header>
</template>

<script>
export default {
  name: "MobileHeader",
};
</script>

<style scoped></style>
