<template>
  <div class="auth">
    <MobileHeader class="block lg:hidden pt-4 pl-4" />

    <main class="">
      <section class="hidden lg:flex flex-col justify-center items-center">
        <img
          alt="ansaa logo"
          src="../assets/images/logo.svg"
          class="w-56 h-42"
        />
        <img alt="ansaa logo text" src="../assets/images/logo_text-white.svg" />
      </section>

      <section class="form">
        <div>
          <h5 class="text-ansGreen text-2xl font-bold text-center">
            Update your password
          </h5>
        </div>

        <Lines class="pt-8" />

        <!-- FORM AREA -->
        <!-- OTP Verify -->
        <div v-if="!otpVerified">
          <div class="pt-6">
            <label for="otp">Enter OTP</label>
            <input
              type="text"
              class="input"
              id="otp"
              pattern="/^-?\d+\.?\d*$/"
              onKeyPress="if(this.value.length==4) return false;"
              placeholder="Enter OTP"
              v-model="form.otp"
            />
          </div>

          <div class="mt-6">
            <button
              :disabled="!otpReady || otpProcessing"
              type="submit"
              @click="checkotp"
              class="flex justify-center bg-ansLemon text-white py-3 w-full rounded-xl"
            >
              <span class="mr-1">Verify OTP</span>
              <spinner v-if="otpProcessing" />
            </button>
          </div>
        </div>

        <!-- Password update -->
        <div v-else>
          <div class="pt-6">
            <label for="password">New Password</label>
            <PasswordInput
              id="password"
              v-model="form.password"
              placeholder="Enter a new password"
            />
          </div>

          <div class="pt-6">
            <label for="confirmPassword">Confirm new Password</label>
            <PasswordInput
              id="confirmPassword"
              v-model="form.confirmPassword"
              placeholder="Confirm your new password"
            />
          </div>

          <div class="mt-6">
            <button
              :disabled="!updateReady || updateProcessing"
              type="submit"
              @click="update"
              class="flex justify-center bg-ansLemon text-white py-3 w-full rounded-xl"
            >
              <span class="mr-1">Update Password</span>
              <spinner v-if="updateProcessing" />
            </button>
          </div>
        </div>

        <!-- Remembered part -->
        <div class="flex justify-center mt-6 text-xs">
          <span class="text-oldSilver"> Didn't get OTP? </span>

          <button
            @click="resendOtp"
            :disabled="resendProcessing"
            class="text-spanGreen ml-1 font-medium"
          >
            Resend OTP
          </button>
        </div>

        <div class="flex justify-center mt-6 text-xs">
          <span class="text-oldSilver"> Remembered your password? </span>

          <router-link
            :to="{ name: 'login' }"
            class="text-spanGreen ml-1 font-medium"
          >
            Login here
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Lines from "@/components/Lines.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import MobileHeader from "@/components/auth/MobileHeader.vue";

export default {
  name: "UpdatePassword",
  components: {
    PasswordInput,
    Lines,
    MobileHeader,
  },
  data() {
    return {
      otpVerified: false,
      resendProcessing: false,
      otpProcessing: false,
      updateProcessing: false,
      form: {
        type: 3,
        otp: null,
        username: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  mounted() {
    this.form.username = this.$store.getters.userPhone;
  },
  computed: {
    otpReady() {
      return !!this.form.otp;
    },
    updateReady() {
      return (
        this.form.confirmPassword &&
        this.form.password &&
        this.form.confirmPassword == this.form.password
      );
    },
  },
  methods: {
    async resendOtp() {
      try {
        this.resendProcessing = true;

        const res = await this.$http.post("/get-otp", this.form, {
          headers: { noauth: true },
        });

        this.resendProcessing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$store.dispatch("saveUserInfo", { phone: this.form.username });
      } catch (err) {
        console.log(err);
        this.resendProcessing = false;
      }
    },

    async checkotp() {
      try {
        this.otpProcessing = true;

        const res = await this.$http.post("/verify-otp", this.form, {
          headers: { noauth: true },
        });

        this.otpProcessing = false;
        if (!res) {
          return;
        }

        this.otpVerified = true;
      } catch (err) {
        console.log(err);
        this.otpProcessing = false;
      }
    },

    async update() {
      try {
        this.updateProcessing = true;

        const res = await this.$http.post("/reset-pasword", this.form, {
          headers: { noauth: true },
        });

        this.updateProcessing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push({ name: "login" });
      } catch (err) {
        console.log(err);
        this.updateProcessing = false;
      }
    },
  },
};
</script>

<style scoped>
main {
  @apply flex justify-evenly items-center;
  height: 100vh;
  width: 100%;
}
.form {
  @apply py-8 px-4 lg:py-24 lg:px-10 w-11/12;
  background: #ffffff;
  border-radius: 2rem;
  max-width: 31.25rem;
}
</style>
